@font-face {
  font-family: 'metropolis-light';
  src: url('../fonts/Metropolis-Light.eot');
  src: local('metropolis-light'), local('metropolis-light'),
    url('../fonts/Metropolis-Light.otf') format('opentype');
}

h1,
h2 {
  font-family: 'metropolis-light', sans-serif;
}
