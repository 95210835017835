@import './bootstrap.scss';
@import './utils.scss';

.logo-small {
  min-width: 2.5rem;
  max-width: 2.5rem;
  min-height: 2.5rem;
  max-height: 2.5rem;
}

.logo-medium {
  min-width: 5.25rem;
  max-width: 5.25rem;
  min-height: 5.25rem;
  max-height: 5.25rem;
}

.bg-compex-logo {
  @extend .bg-compex-logo;
  height: 43px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: -15px 0px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;

  input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
.message {
  .activemessage {
    text-decoration: none !important;
    background-color: white;
    color: #f60;
    border-radius: 15px;
    border: none;
  }
  .inactivemessage {
    background-color: #e1e1e1;
    color: #000;
    text-decoration: none !important;
  }
  .messagebox::-webkit-scrollbar {
    width: 9px;
  }
  .messagebox::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 30px;
  }
  .messagebox::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 30px;
  }

  .messagebox::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 30px;
  }

  .messagebox::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 82px;
  }
}

.postlisting {
  overflow-x: hidden;
  .technology {
    width: 12px;
    height: 12px;
    background-color: #00ca93;
  }
  .design {
    width: 12px;
    height: 12px;
    background-color: #0051ca;
  }
  .fashion {
    width: 12px;
    height: 12px;
    background-color: #ca1200;
  }
  .width {
    width: 3px;
  }
  .event {
    width: 12px;
    height: 12px;
    background-color: #fd7900;
  }
  @media (max-width: 1090px) {
    .mobile {
      margin-left: 1px !important;
      margin-right: 10% !important;
      width: 100% !important;
    }
  }
}

.avaterImageDiv {
  border-radius: 50%;
}

#postXBody img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}
:root {
  --ck-image-style-spacing: 1.5em;
}
#postBody {
  img {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
  }
  & .image-style-side,
  & .image-style-align-left,
  & .image-style-align-center,
  & .image-style-align-right {
    max-width: 50%;
  }

  & .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  & .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }

  & .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  & .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }
}

#postBody .image.image_resized {
  max-width: 100%;

  display: block;
  box-sizing: border-box;

  & img {
    width: 100%;
  }

  & > figcaption {
    display: block;
  }
}

#postBody .image {
  display: table;
  clear: both;
  text-align: center;

  margin: 1em auto;

  & > img {
    display: block;

    margin: 0 auto;

    max-width: 100%;

    min-width: 50px;
  }
}

#postBody .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0, 0%, 20%);
  background-color: hsl(0, 0%, 97%);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
