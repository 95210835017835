@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

$body-bg: #f4f4f4;

$theme-colors: (
  'primary': #ff6600,
  'black': #000000,
  'graylight': rgba(218, 234, 245, 0.25),
  'seen': rgba(238, 238, 238, 0.4),
  'footer': #080808,
  'footer-shade': #b3b8c3,
  'sky-grey': #f9f9f9,
  'light-grey': #f4f4f4,
  'form': rgba(255, 255, 255, 0.62),
  'off-black': #060606,
  'body': $body-bg,
  'faded-black': #717171,
  'post-gray': #e6e6e6,
  'link-blue': #00388c,
);

::placeholder {
  color: black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}

$font-sizes: (
  1a: 2.875rem,
  1b: 3rem,
  1c: 2.0625rem,
  1d: 2.625rem,
  1e: 1.375rem,
  1z: 1.5625rem,
  1y: 1.0625rem,
  2a: 1.25rem,
  4a: 1rem,
  5: 0.9375rem,
  7: 0.875rem,
  7y: 0.8125rem,
  7z: 0.6875rem,
  8: 0.625rem,
  8a: 0.8rem,
  9: 0.9rem,
  10: 0.6rem,
  11: 0.5rem,
);

$widths: (
  0: 0,
  5: 5,
  9a: 9.4,
  10: 10,
  15: 15,
  16: 16,
  20: 20,
  25: 25,
  30: 30,
  38: 38,
  50: 50,
  55: 55,
  56: 56,
  56a: 56.4,
  40: 40,
  60: 60,
  62: 62,
  70: 70,
  72: 72,
  73: 73,
  80: 80,
  85: 85,
  90: 90,
  94: 94,
  99: 99,
  100: 100,
);

$rem-units: (
  2: 1.5rem,
  2a: 1.875rem,
  3: 3.5rem,
  3a: 4.2rem,
  6: 6.75rem,
  7: 7.4rem,
  13: 13rem,
  17: 17.625rem,
  20: 20.5rem,
  22: 22rem,
  23: 23rem,
  24: 24rem,
  34: 34rem,
  36: 36rem,
  37: 37rem,
  42: 42rem,
  44: 44.4rem,
  50: 50rem,
  53: 53rem,
  60: 60rem,
  64: 64rem,
  70: 70rem,
  76: 76rem,
  75: 75rem,
  80: 80rem,
  82: 82rem,
  90: 90rem,
  94: 94rem,
  100: 100rem,
  101: 101rem,
  110: 110rem,
  120: 120rem,
  125: 125rem,
  140: 140rem,
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    55: 55%,
    60: 60%,
    65: 65%,
    68: 68%,
    70: 70%,
    75: 75%,
    80: 80%,
    85: 85%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);

$rem-sizes: (
  unit: 1rem,
  action: 3.125rem,
  sub: 2.5rem,
  content: 1.1875rem,
  info: 1.5rem,
  major: 1.5625rem,
  business: 2rem,
  how: 3rem,
  news: 4rem,
  disrupt: 6rem,
  organgy: 1.875rem,
  almost: 0.9375rem,
  social: 13rem,
  below: 0.75rem,
  comment: 0.85rem,
);

$ints: (
  1: 1,
  5: 5,
  10: 10,
  12: 12,
  13: 13,
  14: 14,
  15: 15,
  20: 20,
  24: 24,
  25: 25,
  30: 30,
  35: 35,
  40: 40,
  50: 50,
  55: 55,
  60: 60,
  70: 70,
  85: 85,
  90: 90,
  100: 100,
);

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    2a: (
      $spacer * 0.875,
    ),
    3a: (
      $spacer * 4.8,
    ),
    5a: (
      $spacer * 3.8,
    ),
    5b: (
      $spacer * 3.5,
    ),
    6: (
      $spacer * 6.5,
    ),
    6a: (
      $spacer * 7.4,
    ),
    7: (
      $spacer * 8.5,
    ),
    8: (
      $spacer * 10.5,
    ),
    9: (
      $spacer * 15.5,
    ),
  ),
  $spacers
);

/*
Add images here to use the custom bg-<image-key> utility
you can choose to add only png or jpg versions in the png-img-urls map
or to also include a webp version in the web-img-urls map

When a webp version exists, it is used over the png version in a progressive manner.
*/
$png-img-urls: (
  'investor': '../images/placeholder-man.png',
  'community': '../images/placeholder-people.png',
  'docs': '../images/placeholder-docs.png',
  'landing': '../images/city.png',
  'investor-portrait': '../images/placeholder-investor.png',
  'testimonial-portrait': '../images/testimonial-man.png',
  'businessday': '../images/businessday.png',
  'cnbc': '../images/cnbc-africa.png',
  'disrupt-africa': '../images/disrupt-africa.png',
  'how-we': '../images/how-we.png',
  'techcabal': '../images/techcabal.png',
  'bella': '../images/bella.png',
  'eburu': '../images/eburu.png',
  'daisy': '../images/daisy.png',
  'helens': '../images/helens.png',
  'scenery': '../images/scenery.png',
  'logo': '../images/logo.png',
  'compex-logo': '../images/compex-white-logo.svg',
  'google-icon': '../images/google-icon.svg',
  'facebook-icon': '../images/facebook-icon.png',
  'compex-icon': '../images/compex-icon.png',
);

$webp-img-urls: (
  'landing': '../images/city.webp',
  'investor-portrait': '../images/placeholder-investor.webp',
  'testimonial-portrait': '../images/testimonial-man.webp',
  'businessday': '../images/businessday.webp',
  'cnbc': '../images/cnbc-africa.webp',
  'disrupt-africa': '../images/disrupt-africa.webp',
  'how-we': '../images/how-we.webp',
  'techcabal': '../images/techcabal.webp',
  'bella': '../images/bella.webp',
  'eburu': '../images/eburu.webp',
  'daisy': '../images/daisy.webp',
  'helens': '../images/helens.webp',
  'scenery': '../images/scenery.webp',
  'logo': '../images/logo.webp',
);

$investor-avatar: '../images/investor-avatar.png';
$advisor-avatar: '../images/advisor-avatar.png';

$urls: (
  investor-avatar: $investor-avatar,
  advisor-avatar: $advisor-avatar,
);

@each $key, $url in $urls {
  .bg-#{$key} {
    content: url($url);
    max-width: 0.75rem;
  }
}

$bg-positions: (
  top: top,
  bottom: bottom,
  center: center,
);

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis'),
    url(../fonts/Metropolis-Regular.woff) format('woff2');
  font-family: 'Metropolis Extra Bold';
  src: local('Metropolis Extra Bold'),
    url(../fonts/Metropolis-Bold.woff) format('woff2');
}

$font-family-sans-serif: 'Metropolis',
  // Safari for OS X and iOS (San Francisco)
    -apple-system,
  // Chrome < 56 for OS X (San Francisco)
    BlinkMacSystemFont,
  // Windows 'Segoe UI',
    // Android
    'Roboto',
  // Basic web fallback
    'Helvetica Neue',
  Arial, sans-serif,
  // Emoji fonts
    'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol' !default;
@font-face {
  font-family: 'metropolis-light';
  src: url('../fonts/Metropolis-Light.eot');
  src: local('metropolis-light'), local('metropolis-light'),
    url('../fonts/Metropolis-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Metropolis-ExtraBold';
  src: local('Metropolis-ExtraBold'),
    url(../fonts/Metropolis-ExtraBold.woff) format('woff2');
}

@font-face {
  font-family: 'Metropolis-SemiBold';
  src: local('Metropolis-SemiBold'),
    url('../fonts/Metropolis-SemiBold.woff') format('woff2');
}
@font-face {
  font-family: 'Metropolis-Medium';
  src: local('Metropolis-Medium'),
    url('../fonts/Metropolis-Medium.woff') format('woff2');
}

.metroBold {
  font-family: 'Metropolis-SemiBold';
}
.downArrow {
  height: 20px;
  width: 20px;
}
.profilePic {
  height: 40px;
  width: 40px;
}
.techCategory {
  height: 20px;
  width: 30px;
}
.category-select select {
  -webkit-appearance: textfield;
  background-image: url(../images/downarrow.png);
  background-repeat: no-repeat;
  background-position: right 15px center;
  appearance: none;
  text-indent: 10px;
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.video-container::after {
  padding-top: 75%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.nav-text {
  font-family: 'Metropolis-Medium';
  color: black;
}

.max-nav {
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 2000px) {
  .max-nav {
    max-width: 2000px;
  }
}
