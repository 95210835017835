@each $with-pc, $width in $widths {
  .w-#{$with-pc} {
    width: unquote($width + '%') !important;
  }

  .min-w-#{$with-pc} {
    min-width: unquote($width + '%') !important;
  }

  .min-vw-#{$with-pc} {
    min-width: unquote($width + 'vw') !important;
  }

  .max-w-#{$with-pc} {
    max-width: unquote($width + '%') !important;
  }

  .h-#{$with-pc} {
    height: unquote($width + '%') !important;
  }

  .vh-#{$with-pc} {
    height: unquote($width + 'vh') !important;
  }

  .min-vh-#{$with-pc} {
    min-height: unquote($width + 'vh') !important;
  }

  .max-h-#{$with-pc} {
    max-height: unquote($width + '%') !important;
  }
}

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-up(xs) {
  @each $with-pc, $width in $widths {
    .w-xs-#{$with-pc} {
      width: unquote($width + '%') !important;
    }
    .h-xs-#{$with-pc} {
      height: unquote($width + '%') !important;
    }
    .pb-xs-#{$with-pc} {
      padding-bottom: unquote($width + '%') !important;
    }
  }
  @each $key, $value in $rem-units {
    .min-h-rem-xs-#{$key} {
      min-height: $value !important;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
  @each $with-pc, $width in $widths {
    .w-sm-#{$with-pc} {
      width: unquote($width + '%') !important;
    }
    .h-sm-#{$with-pc} {
      height: unquote($width + '%') !important;
    }
    .pb-sm-#{$with-pc} {
      padding-bottom: unquote($width + '%') !important;
    }
  }

  @each $key, $value in $rem-units {
    .max-w-rem-sm-#{$key} {
      max-width: $value !important;
    }
  }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
  @each $with-pc, $width in $widths {
    .w-md-#{$with-pc} {
      width: unquote($width + '%') !important;
    }

    .h-md-#{$with-pc} {
      height: unquote($width + '%') !important;
    }

    .mrp-md-#{$with-pc} {
      margin-right: unquote($width + '%') !important;
    }
    .pb-md-#{$with-pc} {
      padding-bottom: unquote($width + '%') !important;
    }
    .min-w-md-#{$with-pc} {
      min-width: unquote($width + '%') !important;
    }
    .min-vh-md-#{$with-pc} {
      min-height: unquote($width + 'vh') !important;
    }
  }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
  @each $with-pc, $width in $widths {
    .w-lg-#{$with-pc} {
      width: unquote($width + '%') !important;
    }

    .h-lg-#{$with-pc} {
      height: unquote($width + '%') !important;
    }
    .pb-lg-#{$with-pc} {
      padding-bottom: unquote($width + '%') !important;
    }
  }

  .shadow-lg-left {
    box-shadow: -2rem 2rem 2rem -1rem rgba(0, 0, 0, 0.06),
      -2rem -2rem 2rem -1rem rgba(0, 0, 0, 0.05) !important;
  }
  .shadow-lg-right {
    box-shadow: 2rem 2rem 2rem -1rem rgba(0, 0, 0, 0.06),
      2rem -2rem 2rem -1rem rgba(0, 0, 0, 0.05) !important;
  }
  .scale-down-lg-9 {
    transform: scale(0.9);
  }

  .scale-down-lg-7 {
    transform: scale(0.75);
  }
}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
  @each $with-pc, $width in $widths {
    .w-xl-#{$with-pc} {
      width: unquote($width + '%') !important;
    }

    .h-xl-#{$with-pc} {
      height: unquote($width + '%') !important;
    }
    .pb-xl-#{$with-pc} {
      padding-bottom: unquote($width + '%') !important;
    }
    @media only screen and (max-height: 1000px) {
      .min-vh-xl-#{$with-pc} {
        min-height: unquote($width + 'vh') !important;
      }
      .vh-xl-#{$with-pc} {
        height: unquote($width + 'vh') !important;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.btn-thick-outline-primary {
  @extend .btn-outline-primary;
  border-width: 3px;
}

.border-thick-primary {
  @extend .border-primary;
  border-width: 3px !important;
}

.border-thick {
  @extend .border;
  border-width: 3px !important;
}

@each $key, $url in $webp-img-urls {
  .webp .bg-#{$key} {
    background-image: url($url);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .no-webp .bg-#{$key} {
    background-image: url($url);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .no-js .bg-#{$key} {
    background-image: url($url);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

@each $key, $url in $png-img-urls {
  .bg-#{$key} {
    background-image: url($url);
    background-size: contain;
    background-repeat: no-repeat;
    //padding-right: 45px;
    //padding-left: 45px;
  }
}

.bg-landing-faded {
  @extend .bg-landing;
  opacity: 0.2;
  z-index: -1;
}

@each $key, $value in $bg-positions {
  .bg-position-#{$key} {
    background-position: $value;
  }
}

.bg-size-cover {
  background-size: cover !important;
}

@each $key, $value in $rem-sizes {
  .font-size-#{$key} {
    font-size: $value;
  }
  .min-h-rem-#{$key} {
    min-height: $value;
  }
  .min-w-rem-#{$key} {
    min-width: $value;
  }
}

@each $key, $value in $ints {
  .rounded-#{$key}-px {
    border-radius: unquote($value + 'px');
  }
  .min-vh-#{$key} {
    min-height: unquote($value + 'vh !important');
  }
  .min-w-#{$key} {
    min-width: unquote($value + '%');
  }
  .min-h-#{$key} {
    min-height: unquote($value + '%');
  }
  .w-#{$key} {
    width: unquote($value + '%');
  }
  .top-#{$key} {
    top: unquote($value + '%');
  }
}

@include media-breakpoint-up(md) {
  @each $key, $value in $ints {
    .min-h-md-#{$key} {
      min-height: unquote($value + '%');
    }
    .min-w-md-#{$key} {
      min-width: unquote($value + '%');
    }
    .w-md-#{$key} {
      width: unquote($value + '% !important');
    }
    .top-md-#{$key} {
      top: unquote($value + '%');
    }
  }
  @each $key, $value in $rem-sizes {
    .font-size-md-#{$key} {
      font-size: $value;
    }
  }
  @each $key, $value in $bg-positions {
    .bg-position-md-#{$key} {
      background-position: $value;
    }
  }
}

@include media-breakpoint-up(lg) {
  @each $key, $value in $ints {
    .min-vh-lg-#{$key} {
      min-height: unquote($value + 'vh !important');
    }
    .w-lg-#{$key} {
      width: unquote($value + '%');
    }
  }
}

@media (min-width: 2048px) {
  @each $key, $value in $ints {
    .min-vh-xl-#{$key} {
      min-height: unquote($value + 'vh !important');
    }
  }
}

.custom-center {
  .vh-center-parent {
    max-height: 100% !important;
    height: 100% !important;
    width: 100% !important;
    position: relative;
  }
  .vh-center {
    max-height: 100% !important;
    max-width: 100% !important;
    width: auto;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 600px) {
  #info {
    height: 100% !important;
  }
}

.custom-nav-pills {
  .nav-link {
    color: #000;
  }

  .nav-link.active,
  .show > .nav-link {
    color: black;
    border-bottom: 5px solid #ff6600 !important;
  }
}

.slick-prev,
.slick-next {
  font-size: 15px !important;
}

.slick-prev:before,
.slick-next:before {
  content: '' !important;
}

.text-sm-1 {
  font-size: 0.9rem !important;
}
.text-md-1 {
  font-size: 12px !important;
}
.text-sm-2 {
  font-size: 0.8rem !important;
}

.msg {
  .active {
    background-color: #f4f4f4;
    opacity: 0.5;
  }
  .messageItem:hover {
    background-color: #f4f4f4;
    opacity: 0.5;
  }
}

.button-as-link {
  background: none !important;
  border: none;

  cursor: pointer;
  background-color: transparent;
}
